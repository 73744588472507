// LIVE URL
// export const baseURL = 'https://devapigobooze.codefactstech.com/admin';

// export const productBaseURL = "https://devapigobooze.codefactstech.com/product/v1";
// export const variantsBaseURL = "https://devapigobooze.codefactstech.com/product/v1/variants";
// export const orderURL = 'https://devapigobooze.codefactstech.com/order/api/orders';
export const GOOGLE_MAP_API_KEY = "AIzaSyCtTH8DV1-h4tYTSb-geYjdn71a0Up_63k";
export const socketURL = "https://apigoboozein.codefactstech.com";
// LOCAL URL
//  export const baseURL = 'http://localhost:9000';
// export const productBaseURL = "http://localhost:3500/v1";
//  export const orderURL = 'http://localhost:5002/api/orders';
// export const variantsBaseURL = "http://localhost:3500/v1/variants";
export const imageURL =
  "https://gobooze-tst.s3.ap-southeast-2.amazonaws.com/goboozestore/";


// export const imageURL = 'https://d12keppzk8wa17.cloudfront.net/goboozestore/';

// dev urls

export const baseURL = "https://apigoboozein.codefactstech.com/admin";

export const productBaseURL =
  "https://apigoboozein.codefactstech.com/product/v1";
export const variantsBaseURL =
  "https://apigoboozein.codefactstech.com/product/v1/variants";
export const orderURL =
  "https://apigoboozein.codefactstech.com/order/api/orders";

